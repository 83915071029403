<template>
  <div class="announcement-list plan-list">
    <van-nav-bar left-arrow title="公告通知" @click-left="$router.back()" />
    <van-search
      v-model="queryInfo.name"
      class="search-bar"
      placeholder="请输入关键字"
      @clear="onRefresh"
      @search="onRefresh"
      @input="onRefresh"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="search-query">
      <div class="trouble-total" style="margin-top: 0">共{{ total }}条结果</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="announcement-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="item.id"
          :class="[
            'announcement-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div class="name">{{ item.name }}</div>
          <span class="time">{{ item.utime }}</span>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import "@/views/plans/styles/list.scss";
import { getPageNotices } from "@/api/psm";

export default {
  name: "AnnouncementList",
  data() {
    return {
      list: [],
      total: 0,
      queryInfo: {
        name: "",
        publishScope: "",
        orgCode: "",
        status: 1,
        size: 10,
        page: 1
      },
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  activated() {
    const { department, orgCode } = this.userInfo;
    this.queryInfo.publishScope = department;
    this.queryInfo.orgCode = orgCode;
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        console.log(this.queryInfo, "this.queryInfo");
        const { list, total } = await getPageNotices(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({
        name: "AnnouncementDetails",
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss">
.announcement-list {
  &__wrapper {
    overflow-y: auto;
    height: calc(100vh - 58px - 70px - 28px);
  }
  &__rows {
    padding-bottom: 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: #3b4664;
    line-height: 20px;
    .van-cell__value {
      position: relative;
      padding-bottom: 28px;
      border-bottom: 1px solid #e1e3e8;
      .time {
        position: absolute;
        right: 0;
        bottom: 8px;
        font-size: 12px;
        line-height: 12px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #aeb3c0;
      }
    }
  }
}
</style>
